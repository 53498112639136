<template>
  <div id="profile">
    <div class="form ms-5">
      <avatar-form :user="loggedInUser" :can-update-profile="true" @upload-avatar="uploadAvatar" />

      <profile-form
        v-if="loggedInUser && !hasTicketProfile"
        :isLoading="isLoading"
        :user="loggedInUser"
        :can-update-profile="true"
        @update-user="updateUser"
        class="form"
      />

      <profile-ticket-form
        v-if="hasTicketProfile"
        :ticketForm="event.ticket.ticketUserProfile"
        :isDialog="false"
        @update-profile="updateTicketProfile"
        @get-profile="getProfileTicket"
        :profileIsLoaded="profileIsLoaded"
        :profileIsLoading="profileIsLoading"
        :profileError="profileError"
        :profile="profile"
      />

      <password-form
        :user="loggedInUser"
        :can-update-password="true"
        @update-password="updatePassword"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { UserTicketProfile, ProfileItemType } from '@/models';

import ProfileForm from '@/components/profile/profile-form/ProfileForm.vue';
import ProfileTicketForm from '@/components/profile/profile-ticket-form/ProfileTicketForm.vue';
import PasswordForm from '@/components/profile/password-form/PasswordForm.vue';
import AvatarForm from '@/components/profile/avatar-form/AvatarForm.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import { APP_USER_MODULE, USER } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  UPDATE_AVATAR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER,
  UPDATE_TICKET_PROFILE,
  GET_TICKET_PROFILE,
} from '@/stores/agnostic/actions/user/agnostic-user.actions';
import {
  APP_EVENT_MODULE,
  REFRESH_EVENT_COMPLETED,
} from '@/stores/umanize-app/actions/event/app-event.actions';

export default {
  name: 'Profile',
  components: {
    AvatarForm,
    PasswordForm,
    ProfileTicketForm,
    ProfileForm,
  },
  data: () => ({
    ProfileItemTypes: ProfileItemType,
    profileForm: new UserTicketProfile(),
    firstnameRules: VALIDATORS.REQUIRED.FIRSTNAME,
    lastnameRules: VALIDATORS.REQUIRED.LASTNAME,
    rules: {
      phone: VALIDATORS.PHONE.FORMAT,
    },
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_USER_MODULE, [
      'loggedInUser',
      'error',
      'profile',
      'profileIsLoading',
      'profileIsUpdated',
      'profileIsLoaded',
      'profileError',
    ]),
    hasTicketProfile() {
      return (
        !this.loggedInUser.isAdmin &&
        !!this.$route.params.eventId &&
        !!this.event?.ticket?.ticketUserProfile?.profileItems
      );
    },
    isLoading() {
      return !!this.$store.getters[`${APP_USER_MODULE}/isSending`];
    },
  },
  methods: {
    ...mapActions(APP_USER_MODULE, [
      USER,
      UPDATE_USER,
      UPDATE_TICKET_PROFILE,
      GET_TICKET_PROFILE,
      UPDATE_USER_PASSWORD,
      UPDATE_AVATAR,
    ]),
    ...mapActions(APP_EVENT_MODULE, [REFRESH_EVENT_COMPLETED]),
    async updateUser(profileForm) {
      await this[UPDATE_USER]({ user: profileForm });
    },
    async updatePassword(passwordForm) {
      await this[UPDATE_USER_PASSWORD]({ user: this.loggedInUser, password: passwordForm });
    },
    async uploadAvatar(file) {
      await this[UPDATE_AVATAR]({ user: this.loggedInUser, file });
    },
    async getProfileTicket() {
      if (this.event?.ticket?.ticketUserProfile?.profileItems) {
        await this[GET_TICKET_PROFILE]({
          user: {
            ticketId: this.event.ticket.ticketId,
            ticketUserProfileId: this.event.ticket.id,
            userId: this.loggedInUser.id,
          },
        });
      }
    },
    async updateTicketProfile(profileForm, isValid) {
      const isCompleted = this.event?.ticket?.userProfileCompleted;
      await this[UPDATE_TICKET_PROFILE]({
        user: {
          ...profileForm,
          completed: isValid,
          userId: this.loggedInUser.id,
        },
      });

      if (!isCompleted) {
        this[REFRESH_EVENT_COMPLETED]();
      }
    },
    translate(fr, en) {
      if (this.$root.$i18n.locale.toUpperCase().includes('FR')) {
        return fr;
      }
      return en;
    },
  },
  watch: {
    profileIsUpdated(isUpdated) {
      if (isUpdated) {
        this.$router.push({
          name: 'Lobby',
          params: {
            eventId: this.$route.params?.eventId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/core/variables';

#profile {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;
}

@include breakpoint(medium) {
  #profile {
    padding: 2rem 0;
  }

  .form {
    width: 50rem;
  }
}
</style>
